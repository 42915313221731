<!-- @component
	Organism: the footer component that appears on every page
 -->

<script lang="ts">
	// Context API
	import { getContext } from "svelte";

	// types
	import type { Footer } from "$types/types";

	// props
	export let footer: Footer;
	export let year = "2023";

	// variables used below
	let index = 0;
	let item = "";
</script>

<template lang="pug">
	footer#footer.font-light.w-full.bg-zinc-800.pt-16.pb-24.text-ghostWhite.page-x-padding
		//- footer top row
		.w-full.grid.grid-cols-1.border-b.pb-16.mb-6(
			class="border-ghostWhite/40 lg:gap-x-16 lg:grid-cols-3"
		)
			//- address & contact button group
			.text-center.mb-20(
				class="md:text-left md:col-span-1"
			)
				//- address
				.opacity-70.mb-7.whitespace-nowrap
					+each('footer.address as item')
						div { item }

				//- contact button
				a.max-w-fit.px-4.py-3.rounded-md.text-sm.bg-appleGreen.text-ghostWhite.opacity-90.cursor-pointer(
					class!="text-[0.875em] shadow-night/10 shadow-md transition-opacity hover:shadow-xl hover:opacity-100 ",
					href!="{ footer.contact.href }",
					title!="{ footer.contact.title }"
				) Contact Us

			//- main navigation menus
			.w-full(
				class=`
					gap-6
					grid
					grid-cols-2
					sm:grid-cols-3
					lg:col-span-2
					lg:grid-cols-3
					xl:grid-cols-3`
			)
				+each('footer.main as menu')
					div
						//- menu heading
						.mb-2(
							class=`
							text-appleGreen
							text-[1.125em]
							whitespace-nowrap
							`
							) { menu.heading }

						//- menu items list
						ul.w-full.grid.grid-cols-1.gap-2
							+each('menu.menuItems as item')
								li: a.opacity-70(
									href!="{ item.href }",
									rel!="{ item.rel }",
									target!="{ item.target }"
									title!="{item.title}"
								) { item.text }

		//- footer bottom row
		.grid.grid-cols-2.gap-4.place-items-center(
			class=`
				text-[0.85em]
				sm:grid-cols-1
				sm:flex
				sm:place-items-start
				sm:gap-x-6
				sm:flex-wrap
				sm:justify-center
				md:justify-start`
		)
			//- legal links
			+each('footer.legal as item')
				a.legal(
					href!="{ item.href }",
					rel!="{ item.rel }",
					title!="go to {item.text}"
				) { item.text }

			//- attribution link
			a.legal.col-span-2(
				class=`
					sm:col-span-1`,
					href!="{ footer.attribution.href }",
					target!="{ footer.attribution.target }",
					rel!="{ footer.attribution.rel }",
					title!="go to Lightning Jar"
				) { footer.attribution.text }

			//- copyright
			.opacity-60.col-span-2.pt-12.text-center(
				class=`
					text-[.95em]
					sm:pt-4
					md:text-start
					lg:w-full
					w-full
					xl:pt-0
					xl:w-auto
					xl:justify-self-end
					xl:text-right
					xl:flex-grow`
			)
				.block(class="sm:inline-block") © { year } Zenith Capital Partners LLC.
				.hidden(class="sm:inline-block") &nbsp;&nbsp;
				.block(class="sm:inline-block") All rights reserved.
</template>
