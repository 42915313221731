<!-- @component
    Molecule: a flyout menu that slides in from the left when the hamburger icon that appears at breakpoints below lg is clicked.
-->

<script lang="ts">
	// context api
	import { getContext } from "svelte";

	// stores
	import {
		hamburger,
		mobileNavIsOpen,
		mobileLinkFirst,
		mobileLinkLast,
	} from "$stores/navStore";

	// utils
	import { closeMobileNav, setFocusOnHamburger } from "$utils/utils";

	// components
	import ButtonClose from "$atoms/ButtonClose.svelte";

	// types
	import type { NavItem } from "$types/types";

	// props
	export let navItems: NavItem[] = [];
	export let classes = "";

	// functions
	// function closeMobileNav() {
	// 	$mobileNavIsOpen = $mobileNavIsOpen ? false : true;
	// }

	// refs
	let menu: HTMLDivElement;

	// refs - up to ten mobile links
	let link0: HTMLAnchorElement;
	let link1: HTMLAnchorElement;
	let link2: HTMLAnchorElement;
	let link3: HTMLAnchorElement;
	let link4: HTMLAnchorElement;
	let link5: HTMLAnchorElement;
	let link6: HTMLAnchorElement;
	let link7: HTMLAnchorElement;
	let link8: HTMLAnchorElement;
	let link9: HTMLAnchorElement;

	//- referencing the links in an array makes it easier to bind them
	$: links = [
		link0,
		link1,
		link2,
		link3,
		link4,
		link5,
		link6,
		link7,
		link8,
		link9,
	];

	// reference first and last bound links
	$: firstLink = links[0];
	$: lastLink = links[navItems.length - 1];

	// assign those values to stores
	$: $mobileLinkFirst = firstLink;
	$: $mobileLinkLast = lastLink;

	// variables
	let index: number;
	let itemFocus: number;
	let navItem: NavItem;
	let ariaHidden: string | null;

	$: ariaHidden = !$mobileNavIsOpen ? "" : null;
	// $: itemFocus = 0;

	// functions
	function onKeydown(e: KeyboardEvent) {
		//- close menu with escape key
		if (e.key === "Escape") {
			closeMobileNav();

			//- focus trapping
		} else if (e.key === "Tab") {
			const length = navItems.length;
			const currentFocus = document.activeElement;
			const id = currentFocus?.id;

			// - if focus is on a menu item
			if (currentFocus instanceof HTMLAnchorElement) {
				const currentIndex = currentFocus
					? Number(currentFocus.dataset?.navIndex)
					: 0;

				// - if focus is on the last menu item
				if (currentFocus === $mobileLinkLast) {
					if (e.shiftKey) {
						// default functionality is fine
					} else {
						e.preventDefault();
						setFocusOnHamburger();
					}
				}
				// - if focus is on the first menu item
				else if (currentFocus === $mobileLinkFirst) {
					if (e.shiftKey) {
						e.preventDefault();
						setFocusOnHamburger();
					} else {
						// default functionality is fine
					}
				}
			}
		}

		//- selecting link
		else if (e.key === " " || e.key === "Enter") {
			closeMobileNav();
		}

		//- disable arrow keys so window doesn't scroll
		else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
			e.preventDefault();
		}
	}
</script>

<template lang="pug">
	#mobile-nav.text-ghostWhite.w-screen.pt-20.px-6.relative.text-20.grid.grid-cols-1.gap-4.place-content-start(
		class="sm:w-96 bg-night min-h-[100vh] lg:hidden lg:pointer-events-none lg:select-none {classes}",
		aria-hidden!="{ ariaHidden }",
		aria-orientation="vertical",
		bind:this!="{ menu }",
		on:keydown!="{ onKeydown }",
		role="menu"
	)

		//- menu links
		+each('navItems as navItem, index')
			a.inline-block.opacity-90.px-6.py-2.tracking-wider.max-w-fit(
				bind:this!="{ links[index] }",
				class="text-20 focus-visible:outline outline-1 outline-offset-4 rounded outline-ghostWhite/80 bg-appleGreen/30",
				data-nav-index!="{ index }",
				href!="{ navItem.link }",
				id!="mobile-nav-item-{ index }",
				on:click!="{ closeMobileNav }",
				rel!="{ navItem.rel }",
				role="menuitem",
				tabindex!="{$mobileNavIsOpen ? null : '-1'}",
				title!="{ navItem.title }"
			) { navItem.name }
</template>
