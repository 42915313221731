<!-- @component
	Atom: The hamburger icon that opens the flyout menu (FlyMenu.svelte molecule).
	Disappears and is replaced with top nav at lg breakpoint.
-->

<script lang="ts">
	// stores
	import { mobileNavIsOpen, hamburger } from "$stores/navStore";

	// utils
	import {
		closeMobileNav,
		setFocusOnBrandLink,
		setFocusOnFirstMobileNavLink,
		setFocusOnLastMobileNavLink,
		toggleMobileNav,
	} from "$utils/utils";

	function onClick() {
		toggleMobileNav();
	}
	function onKeydown(e: KeyboardEvent) {
		//- close menu if user presses escape
		if ($mobileNavIsOpen && e.key === "Escape") {
			closeMobileNav();
		}
		//- navigate menu if user presses Tab or Tab + Shift
		else if ($mobileNavIsOpen && e.key === "Tab" && e.shiftKey) {
			e.preventDefault();
			setFocusOnLastMobileNavLink();
		} else if (e.key === "Tab" && $mobileNavIsOpen) {
			e.preventDefault();
			setFocusOnFirstMobileNavLink();
		} else if (!$mobileNavIsOpen && e.key === "Tab" && !e.shiftKey) {
			e.preventDefault();
			setFocusOnBrandLink();
		}
	}

	// refs
	export let hamburgerRef: HTMLButtonElement;
	$: $hamburger = hamburgerRef;

	// style classes

	$: hamburgerClasses = `
			${$mobileNavIsOpen ? "fixed" : "absolute"}
			appearance-none
			h-8
			w-8
			inline-flex
			items-center
			left-6
			justify-center
			rounded-md
			top-5
			focus:outline-ghostWhite
			outline-2
			outline-offset-4
			focus:outline-neutral-200
			z-40
			lg:hidden`;

	//- static crossbar styles
	const crossbarStyleBase = `
		absolute
		bg-ghostWhite
		w-8
		pointer-events-none
		h-[7%]
		rounded
		origin-center
		transition-transform
	`;

	//- dynamic crossbar styles
	// bars rotate 45 degrees when menu is open
	$: topCrossbarDynamicStyle = $mobileNavIsOpen
		? "rotate-45"
		: "rotate-0 -translate-y-[6px]";
	$: bottomCrossbarDynamicStyle = $mobileNavIsOpen
		? "-rotate-45"
		: "rotate-0 translate-y-[6px]";

	//- combined styles
	$: topCrossbarStyle = crossbarStyleBase + " " + topCrossbarDynamicStyle;
	$: bottomCrossbarStyle = crossbarStyleBase + " " + bottomCrossbarDynamicStyle;
</script>

<template lang="pug">
	button#hamburger(
		bind:this!="{ hamburgerRef }",
		class!="{hamburgerClasses}",
		aria-controls="mobile-nav",
		aria-expanded!="{ $mobileNavIsOpen }",
		aria-haspopup="menu",
		aria-label="open menu",
		on:click!="{ onClick }",
		on:keydown!="{ onKeydown }",
		type="button"
		)
		//- cross bars
		div(class!="{topCrossbarStyle}")
		div(class!="{bottomCrossbarStyle}")
</template>
