<script lang="ts">
	// enable Tailwind
	import "../app.css";

	// import child page data for metadata
	import { page } from "$app/stores";

	// context api
	import { setContext } from "svelte";

	// Components
	import GlobalHeader from "$organisms/GlobalHeader.svelte";
	import GlobalFooter from "$organisms/GlobalFooter.svelte";
	import MobileNav from "$molecules/MobileNav.svelte";
	import ButtonPageUp from "$atoms/ButtonPageUp.svelte";
	import TopOfPage from "$molecules/TopOfPage.svelte";
	import HamburgerButton from "$atoms/HamburgerButton.svelte";

	// stores
	import { showScrollToTopButton } from "$stores/scrollToTopStore";
	import { mobileNavIsOpen } from "$stores/navStore";

	// types
	import type { LayoutServerData } from "./$types";

	// load data
	export let data: LayoutServerData;

	// variables
	$: slug = $page.url.pathname;
	$: console.log($page.url.pathname);
	$: mobileNavContainerClasses = $mobileNavIsOpen
		? "translate-x-0 pointer-events-auto"
		: "translate-x-[-100vw] pointer-events-none";

	// remove smooth scroll for windows users
	// add "windows" class to html element
	import { onMount } from "svelte";

	onMount(() => {
		if (navigator && navigator.userAgent.includes("Win")) {
			document.documentElement.classList.remove("motion-safe:scroll-smooth");
			document.documentElement.classList.add("windows");
		}
	});
</script>

<template lang="pug">
	//- absolutely positioned invisible element at the top of the page with an id = "top"
	//- also contains a component that tells us if it is on screen or not
	//- this is used to determine if the user has scrolled beyond the top of the page
	svelte:head
		title { $page.data.metaTitle ?? "Zenith Capital" }
		+if('$page.data.metaDescription')
			meta(
				content!="{ $page.data.metaDescription }",
				name="description"
			)
		+if('$page.data.metaNoIndex')
			meta(
				content="noindex",
				name="robots"
			)
		link(
			href!="https://www.zenithcapital.partners{$page.url.pathname}",
			rel="canonical"
		)


	TopOfPage

	//- header
	GlobalHeader(navItems!="{ data.nav }")

	//- page content
	.relative.w-full
		slot

	//- footer
	GlobalFooter(
		year!="{ data.currentYear }",
		footer!="{ data.footer }"
		)

	//- scroll to top button
	.fixed.bottom-8.right-8.z-50(
		class!="transition-all translate-x-[100vw] { $showScrollToTopButton && !$mobileNavIsOpen ? 'opacity-100 translate-x-[1px] pointer-events-auto' : 'opacity-0 pointer-events-none' }"
	)
		ButtonPageUp(slug!="{ slug }")


	//-mobile nav
	.fixed.top-0.left-0.z-30.transition-transform(
		class!="lg:hidden {mobileNavContainerClasses}"
	)
		MobileNav( navItems!="{ data.nav }")

	//- hamburger button
	HamburgerButton
</template>
