<!--     @component
	Organism: a container for top navigation elements at all breakpoints.
 -->

<script lang="ts">
	// assets
	import logoSvg from "$assets/zenith-logo.svg";

	// components
	import HamburgerButton from "$atoms/HamburgerButton.svelte";

	// stores
	import { mobileNavIsOpen, branding, hamburger } from "$stores/navStore";

	// utils
	import { setFocusOnHamburger } from "$stores/navStore";

	// types
	import type { NavItem } from "$types/types";

	// refs
	export let brandEl: HTMLAnchorElement;
	$: $branding = brandEl;

	// props
	export let navItems: NavItem[] = [];

	// functions
	function onKeydown(e: KeyboardEvent) {
		// is Hamburger visible?
		const hamburgerIsVisible = $hamburger
			? window.getComputedStyle($hamburger).display !== "none"
			: false;
		// if user presses Tab + Shift, and hamburger is visible, focus on hamburger
		if (
			e.key === "Tab" &&
			e.shiftKey &&
			!$mobileNavIsOpen &&
			hamburgerIsVisible
		) {
			e.preventDefault();
			setFocusOnHamburger();
		}
		// if user presses Tab + Shift, and hamburger is not visible -> default
		else if (e.key === "Tab" && !hamburgerIsVisible && !$mobileNavIsOpen) {
			// default is fine
		}
	}
</script>

<template lang="pug">
	header.w-full.py-6.page-x-padding.flex.relative.items-center.justify-center.w-full(
			class="lg:justify-start lg:gap-12"
		)
			//- hamburger -- hidden at lg breakpoint
			//- .absolute.flex-none.left-4.top-5.text-white(class="lg:hidden")
			//- 	HamburgerButton

			//- branding
			a.block.drop-shadow-sm(
				aria-label="go to home",
				bind:this!="{ brandEl }",
				class="w-[10em] sm:w-[11em] md:w-[12em] lg:m-0 mt-[-7px] transition-shadow hover:drop-shadow-lg outline-white outline-offset-4",
				on:keydown!="{onKeydown}"
				href="/#top",
				title="go to home"
			)
				img.w-full.h-auto.select-none(
					alt="Zenith Capital Logo",
					decoding="async",
					draggable="false",
					height="55",
					src!="{ logoSvg }",
					width="267"
				)

			//- main navigation -- hidden below lg breakpoint
			nav.hidden.w-full(class="pointer-events-none lg:pointer-events-auto lg:flex lg:justify-end text-mintCream font-light text-[.95em] gap-8 translate-y-1")
				+each('navItems as navItem, index')
					+if('!navItem.hideInDesktop')
						a.block.rounded.outline-current.outline-offset-4.transition-opacity.select-none(
							class="opacity-[95%] hover:opacity-100 hover:text-maize hover:drop-shadow focus:font-normal focus:opacity-100",
							href!="{ navItem.link }",
							id!="main-nav-item-{ index }",
							rel!="{ navItem.rel }",
							tabindex!="{$mobileNavIsOpen ? '-1' : null}"
							title!="{ navItem.title }"
						) { navItem.name }
</template>
