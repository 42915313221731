<!-- @component
	Atom: scroll-to-top button for the main page.
 -->

<script lang="ts">
	export let slug = "";
</script>

<template lang="pug">
	a.flex.items-center.justify-end(
		class="text-ghostWhite outline-none sm:opacity-80 transition-opacity"
		aria-label="scroll to top of page",
		href!="{slug}#top",
		title=""
	)
		//- label
		div(
			class=`
				bg-night/80
				mr-2
				px-2
				py-1
				rounded-md
				text-[.8em]
				`
		) back to top

		//- circle
		div(
			class=`
				aspect-square
				bg-appleGreen
				border
				border-2
				border-mintCream/80
				drop-shadow-xl
				flex
				h-[4em]
				items-center
				justify-center
				rounded-full
				w-[4em]`
		) ▲
</template>
