<!--
@component
This component is for use with the scroll-to-top button.
Place it at the top of your +layout.svelte file
It serves as a scroll target for the scroll-to-to button (#top).
It also has a child component called ParentIsOnScreen that is used to tell if this component is on screen.
If this component is visible on screen then the user has not scrolled beyond the top of the page
When this component is not on screen, the user has scrolled down so we show the scroll-to-top button.
-->

<script lang="ts">
	// components
	import ParentIsOnScreen from "$tools/ParentIsOnScreen.svelte";

	// stores
	import { showScrollToTopButton } from "$stores/scrollToTopStore";

	// reactive variables
	let isOnScreen: boolean;
	$: isOnScreen = false;

	// when the ParentIsOnScreen component tells us that this component is visible on screen, we hide the scroll to top button
	$: {
		$showScrollToTopButton = !isOnScreen;
	}
</script>

<template lang="pug">
	#top.absolute.top-0.left-0.w-full.pointer-events-none.select-none &nbsp;
		ParentIsOnScreen(
			on:isOnScreenTestResults!="{(e) => {isOnScreen = e.detail}}",
			tolerance!="{ 0.25 }"
		)
</template>
